<!--Panorama viewer pannellum wrap.-->
<template>
  <div
    class="vue-pannellum"
    @mouseup="onMouseUp"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
  >
    <div class="info">{{ info }}</div>
    <div class="default-slot">
      <slot/>
    </div>
  </div>
</template>

<script>
import 'pannellum'
import 'pannellum/build/pannellum.css'

export default {
  props: {
    debug: { type: Boolean, default: false },
    srcInput: { type: [String, Object], required: true },
    preview: { type: String, default: '' },
    autoLoad: { type: Boolean, default: true },
    autoRotate: { type: [Number, Boolean], default: 0 },
    orientation: { type: Boolean, default: false },
    draggable: { type: Boolean, default: true },
    mouseZoom: { type: Boolean, default: true },
    doubleClickZoom: { type: Boolean, default: true },
    showInfo: { type: Boolean, default: false },
    showZoom: { type: Boolean, default: false },
    showFullscreen: { type: Boolean, default: false },
    compass: { type: Boolean, default: false },
    hotSpots: { type: Array, default: () => [] },
    hfov: { type: Number, default: 75 }, // Set to a very small value for a flat-like view
    minHfov: { type: Number, default: 1 }, // Allow zooming in further
    maxHfov: { type: Number, default: 120 }, // Limit maximum zoom out to maintain flat-like appearance
    inputYaw: { type: Number, default: 0 },
    inputPitch: { type: Number, default: 0 },
    crossOrigin: {type: String, default: 'anonymous' },
  },
  data () {
    return {
      src: null,
      yaw: 0,
      pitch: 0,
      viewer: null,
      info: '',
      rafId: -1,
    }
  },
  computed: {
    srcOption () {
      if (typeof this.src === 'string') {
        return {
          type: 'equirectangular',
          panorama: this.src,
          hotSpots: this.hotSpots,
        }
      } else if (typeof this.src === 'object') {
        if (this.src.px && this.src.ny) {
          return {
            type: 'cubemap',
            cubeMap: [
              this.src.pz,
              this.src.px,
              this.src.nz,
              this.src.nx,
              this.src.py,
              this.src.ny,
            ],
            hotSpots: this.hotSpots,
          }
        } else if (this.src.scenes) {
          return {
            default: this.src.default,
            scenes: this.src.scenes,
          }
        } else {
          console.error('[vue-pannellum] Unknown src type')
        }
      } else {
        console.error('[vue-pannellum] Unknown src type: ' + typeof this.src)
      }
      return true
    },
  },
  watch: {
    src () {
      this.$el.innerHTML = ''
      this.$nextTick(this.load)
    },
    hfov (val) {
      if (this.viewer) this.viewer.setHfov(val, false)
    },
    yaw (val) {
      if (this.viewer) this.viewer.setYaw(val, false)
    },
    pitch (val) {
      if (this.viewer) this.viewer.setPitch(val, false)
    },
    maxHfov () {
      if (this.viewer) {
        this.viewer.setHfovBounds([this.minHfov, this.maxHfov])
      }
    },
    minHfov () {
      if (this.viewer) {
        this.viewer.setHfovBounds([this.minHfov, this.maxHfov])
      }
    },
    autoRotate (val) {
      if (val) {
        this.viewer.startAutoRotate()
      } else {
        this.viewer.stopAutoRotate()
        if (this.orientation) this.viewer.startOrientation()
      }
    },
    orientation (val) {
      if (val) {
        this.viewer.startOrientation()
      } else {
        this.viewer.stopOrientation()
        if (this.autoRotate) this.viewer.startAutoRotate()
      }
    },
  },
  mounted () {
    this.src = this.srcInput
    if (this.$store.state.pannellumView.latestStage === this.$store.state.currentStage) {
      this.src.default.firstScene = this.$store.state.pannellumView.latestSceneTitle
    }
    this.load()
    this.setPannellumView()
    this.rafId = window.requestAnimationFrame(this.loop)
  },
  beforeDestroy() {
    this.viewer.destroy()
    window.cancelAnimationFrame(this.rafId)
  },
  methods: {
    setPannellumView () {
      if (
        this.$store.state.pannellumView.scenes &&
        this.$store.state.pannellumView.scenes[this.viewer.getScene()] &&
        this.$store.state.pannellumView.scenes[this.viewer.getScene()].stage === this.$store.state.currentStage)
      {
        this.pitch = this.$store.state.pannellumView.scenes[this.viewer.getScene()].pitch
        this.yaw = this.$store.state.pannellumView.scenes[this.viewer.getScene()].yaw
      } else {
        this.pitch = this.inputPitch ? this.inputPitch : 0
        this.yaw = this.inputYaw ? this.inputYaw : 0
      }
    },
    updatePannellumView () {
      this.$store.commit('updatePannelumView', {
        title: this.viewer.getScene(),
        pitch: this.viewer.getPitch(),
        yaw: this.viewer.getYaw()
      })
    },
    load () {
      const options = {
        autoLoad: this.autoLoad,
        autoRotate: this.autoRotate === true ? -2 : 0,
        orientationOnByDefault: this.orientation,
        draggable: this.draggable,
        mouseZoom: this.mouseZoom,
        doubleClickZoom: this.doubleClickZoom,
        compass: this.compass,
        preview: this.preview,
        hfov: this.hfov,
        yaw: this.yaw,
        pitch: this.pitch,
        minHfov: this.minHfov,
        maxHfov: this.maxHfov,
        crossOrigin: this.crossOrigin,
        ...this.srcOption,
      }
      this.viewer = window.pannellum.viewer(this.$el, options)
      this.viewer.on('load', () => {
        this.$emit('load')
      })
      this.viewer.on('error', (err) => {
        this.$emit('error', err)
      })
      this.viewer.on('scenechange', () => {
        this.onSceneChange()
      })
      if (this.showInfo === false) {
        const el = this.$el.querySelector('.pnlm-panorama-info')
        if (el) el.style.visibility = 'hidden'
      }
      if (this.showZoom === false) {
        const el = this.$el.querySelector('.pnlm-zoom-controls')
        if (el) el.style.display = 'none'
      }
      if (this.showFullscreen === false) {
        const el = this.$el.querySelector('.pnlm-fullscreen-toggle-button')
        if (el) el.style.display = 'none'
      }
    },
    loop () {
      this.rafId = window.requestAnimationFrame(this.loop)
      const hfov = this.viewer.getHfov()
      const yaw = this.viewer.getYaw()
      let pitch = this.viewer.getPitch()
      if (pitch > 90) pitch = 90
      else if (pitch < -90) pitch = -90
      if (hfov != this.hfov) this.$emit('update:hfov', hfov)
      if (yaw != this.yaw) this.$emit('update:yaw', yaw)
      if (pitch != this.pitch) this.$emit('update:pitch', pitch)
    },
    onMouseUp () {
      this.updatePannellumView()
      if (this.debug) this.info += ' mu'
    },
    onTouchMove () {
      if (this.debug) this.info += ' tm'
    },
    onTouchEnd () {
      if (this.debug) this.info += ' te'
    },
    onSceneChange () {
      this.setPannellumView()
    }
  },
}
</script>

<style>
.pnlm-ui .pnlm-about-msg {
  display: none !important;
}
.pnlm-ui .pnlm-orientation-button {
  display: none !important;
}
</style>

<style scoped>
.vue-pannellum {
  position: relative;
}
.info {
  position: absolute;
  background-color: hsla(0, 0%, 100%, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.default-slot {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}
</style>
